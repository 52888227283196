import React, { useEffect, useState } from "react";
import AnchorLink from "react-anchor-link-smooth-scroll";

import Layout from "../../components/chat-channel/Layout";
import Container from "../../components/chat-channel/Container";
import KeyResources from "../../components/common/KeyResources";
import TopFormWithCenter from "../../components/common/knowledge/Header";
import Cards from "../../components/common/knowledge/Cards";
import TitleAndMetaTags from "../../components/common/TitleAndHeader";
import { RequestForm } from "../../components/form";

import { OnScrollPopup } from "../conversational-ai-platform/hr-chatbot";
import {GoToPopup} from "../homepage"

export const blogImg = require("../../assets/img/key-resources/bc_1_4.png");
export const whitepaperImg = require("../../assets/img/key-resources/wp_1_4.png");
export const videoImg = require("../../assets/img/key-resources/vc_1_4.png");

export const tick_icon = require("../../assets/img/tick-icon.png");

const resoucesData = [
  {
    image: blogImg,
    header: "10 Best IT Workflow Automation Practices to follow for Chatbots",
    url:
      "https://blog.workativ.com/8-best-it-workflow-automation-practices-to-follow-for-chatbots-designed-with-workativ-assistant/",
    link: "Read Blog",
    alt: "blog"
  },
  {
    image: whitepaperImg,
    header: "Provide a modern, AI self-service experience to your employees.",
    url: "/conversational-ai-platform/self-service-automation",
    link: "Read Whitepaper",
    alt: "whitepaper"
  },
  {
    image: videoImg,
    header: "How to Automate Tier 1 IT Support in Microsoft Teams ",
    url: "https://youtu.be/1jIESrgOkjo",
    link: "Watch Now",
    alt: "videoImg"
  }
];
export const cardData = [
  {
    href: "#section-1",
    header: "Impact of poor self-service on service desk",
    content:
      "When self-service fails, it impacts service desk. More calls could mean more hiring more service desk agents.",
    backgroundClass: "card_one"
  },
  {
    href: "#section-2",
    header: "Automating tasks",
    content:
      "End-to-end automation drives formidable change, replacing traditional methods of carrying out routine tasks.",
    backgroundClass: "card_two"
  },
  {
    href: "#section-3",
    header: "How does Workativ Assistant make your work more efficient?",
    content:
      "Our product provides better and modern self-service led by AI and automation.",
    backgroundClass: "card_three"
  }
];

export default function KnowledgePage() {
  const [isOpen, setOpen] = React.useState(false);

  const [isActive, setIsActive] = React.useState();

  return (
    <>
      <TitleAndMetaTags
        title="AI Self Service vs Traditional Helpdesk Support | HR & IT Help Desk Chatbot "
        description="Provide better and modern self-service led by AI and automation. Improve self-service with Workativ AI Chatbot."
        keywords={["IT Helpdesk Chatbot", "HR Chatbot", "Service Desk Chatbot"]}
        ogTitle="AI Self Service vs Traditional Helpdesk Support | HR & IT Help Desk Chatbot "
        ogDescription="Provide better and modern self-service led by AI and automation. Improve self-service with Workativ AI Chatbot."
      />
      <Container>
      <Layout backgroundColor={"--green-bg mb-0"} logoFor="ASSISTANT">
          <>
            <div className="gen_vs_true traditional_support">
              <section
                className="wv-banner wv-landing-page-1 --green-bg wv-banner-h320"
                id="scrollToHome"
              >
                <TopFormWithCenter>
                  <TopFormWithCenter.Content>
                    Traditional Support Channels vs Workativ Assistant AI
                    self-service
                  </TopFormWithCenter.Content>
                  <TopFormWithCenter.Header>
                    Make the switch to a modern, AI self-service, that’s user
                    friendly and efficient
                  </TopFormWithCenter.Header>

                  <RequestForm isFooterForm={false} />
                </TopFormWithCenter>
             
              </section>
              <section className="wv-section-mv-p pb-200 content_para">
                <div className="container">
                  <div className="row">
                    <div className="col">
                      <p className="wv-md-heading wv-weight-normal wv-text-primary wv-limited-content mb-4 mx-auto">
                        One of the major objectives of implementing a
                        self-service solution is to help employees self-resolve
                        issues and requests without contacting the service desk.
                        A properly implemented self-help portal also means that
                        employees are aware of how to use the services that are
                        available within the organization.
                      </p>
                    </div>
                  </div>
                </div>
              </section>
              <Cards cards={cardData} />
              <section className="wv-main-content wv-section-mv-p0">
                <div className="container">
                  <div className="row">
                    <div className="col-md-3 col-sm-12 col-xs-12 --sh-sm mb-4">
                      <div className="dropdown wv-dropdown">
                        <button
                          className={`link ${isOpen ? "active" : ""}`}
                          onClick={() => setOpen(!isOpen)}
                        >
                          Explore this articles
                        </button>
                        <div
                          id="myDropdown"
                          className={`accordion-item submenu dropdown-content ${
                            !isOpen ? "collapsed" : ""
                          }`}
                        >
                          <li>
                            <AnchorLink
                              offset={320}
                              className="url_manipulation"
                              href="#section-1"
                            >
                              Impact of poor self-service
                            </AnchorLink>
                          </li>
                          <li>
                            <AnchorLink
                              offset={320}
                              className="url_manipulation"
                              href="#section-2"
                            >
                              Automating tasks
                            </AnchorLink>
                          </li>
                          <li>
                            <AnchorLink
                              offset={320}
                              className="url_manipulation"
                              href="#section-3"
                            >
                              Improve self-service with Workativ Assistant
                            </AnchorLink>
                          </li>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3 --hd-sm">
                      <aside className="wv-sidenav max-width-255" id="myHeader">
                        <p className="wv-sm-heading">Explore this article</p>
                        <ul className="mb-5">
                          <li>
                            <AnchorLink
                              offset={() => 100}
                              href="#section-1"
                              className={
                                isActive === " Impact of poor self-service"
                                  ? "url_manipulation active"
                                  : "url_manipulation"
                              }
                              onClick={() => {
                                setIsActive(" Impact of poor self-service");
                              }}
                            >
                              {" "}
                              Impact of poor self-service
                            </AnchorLink>
                          </li>
                          <li>
                            <AnchorLink
                              offset={() => 100}
                              href="#section-2"
                              className={
                                isActive === "Automating tasks"
                                  ? "url_manipulation active"
                                  : "url_manipulation"
                              }
                              onClick={() => {
                                setIsActive("Automating tasks");
                              }}
                            >
                              {" "}
                              Automating tasks
                            </AnchorLink>
                          </li>
                          <li>
                            <AnchorLink
                              offset={() => 100}
                              href="#section-3"
                              className={
                                isActive ===
                                "Improve self-service with Workativ Assistant"
                                  ? "url_manipulation active"
                                  : "url_manipulation"
                              }
                              onClick={() => {
                                setIsActive(
                                  "Improve self-service with Workativ Assistant"
                                );
                              }}
                            >
                              {" "}
                              Improve self-service with Workativ Assistant
                            </AnchorLink>
                          </li>
                        </ul>
                        <AnchorLink offset={200} href="#scrollToHome">
                          {" "}
                          Back to top ↑
                        </AnchorLink>
                      </aside>
                    </div>
                    <div className="col-md-9 col-sm-12 col-xs-12">
                      <div className="wv-content-area">
                        <section className="wv-content-section">
                          <div id="section-1">
                            <div className="row">
                              <div className="col">
                                <h4 className="wv-heading-h4 mb-2">
                                  Adopting self-service
                                </h4>
                                <p className="wv-md-heading1 wv-weight-normal wv-text-primary wv-limited-content mb-3 ">
                                  Self-service portal is one of the most popular
                                  and tactical way to provide cost effective
                                  support. And one of the key objectives of
                                  self-service portal is to deflect or reduce
                                  calls to service desk.
                                </p>
                                <p className="wv-md-heading1 wv-weight-normal wv-text-primary wv-limited-content mb-3 ">
                                  To get employees to adopt using the
                                  self-service, it is important to have a portal
                                  that works for employees in terms of
                                  simplicity, ease-of-use and intuitiveness.
                                </p>
                                <p className="wv-md-heading1 wv-weight-normal wv-text-primary wv-limited-content">
                                  A few hindrances in adopting the self-service
                                  are poor design, lack of personal touch,
                                  limited availability and more importantly,
                                  loss of productivity in going through myriad
                                  of content. Due to these reasons,
                                  organizations are unable to get employees to
                                  move away from calling or emailing for
                                  assistance despite providing self-help
                                  portals.
                                </p>
                              </div>
                            </div>
                          </div>
                        </section>
                        <section className="bg_section_knowledge bg_section_1">
                          <div className="container">
                            <div className="row">
                              <div className="col pr-0 pl-0  -call-reflection">
                                <div className="container">
                                  <div className="main-slider-left">
                                    <h1>
                                      When self-service fails, it impacts
                                      service desk
                                    </h1>
                                    <div class="row justify-content-center">
                                      <div class="col-md-6 wv-section-mv-p ">
                                        <span class="wv-number mb-4">1</span>
                                        <p class="wv-md-heading1 wv-weight-normal wv-text-primary wv-limited-content">
                                          When self-service fails, it impacts
                                          service desk. More calls could mean
                                          hiring more service desk agents.
                                          Scaling a service desk is expensive
                                          and the quality of the service desk is
                                          directly proportional to horizontal
                                          scale.
                                        </p>
                                      </div>
                                      <div class="col-md-6 wv-section-mv-p">
                                        <span class="wv-number mb-4">2</span>
                                        <p class="wv-md-heading1 wv-weight-normal wv-text-primary wv-limited-content">
                                          A higher bandwidth is required to
                                          improve the resolution time since more
                                          tickets would mean creating larger
                                          teams to resolve internal issues and
                                          manage customer support. To add to
                                          that, it would require teams in shifts
                                          to operate a 24/7 service desk.
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </section>
                        <section className=" wv-content-section">
                          <div id="section-2">
                            <div className="row">
                              <div className="col">
                                <h4 className="wv-heading-h4 mb-2">
                                  Automating tasks
                                </h4>
                                <p className="wv-md-heading1 wv-weight-normal wv-text-primary wv-limited-content mb-3">
                                  According to Pink Elephant, a world leader in
                                  IT management, ITIL and ITSM, 70% to 80% of
                                  resources are spent on operational activities:
                                  executing service requests, closing incident
                                  tickets, and delivering changes.
                                </p>
                                <p className="wv-md-heading1 wv-weight-normal wv-text-primary wv-limited-content mb-5">
                                  Automation comes to the rescue as
                                  organizations can automate all these tasks,
                                  driving formidable change and reigning in the
                                  benefits of a more efficient helpdesk.
                                </p>
                              </div>
                            </div>
                          </div>
                          <section className="bg_section_knowledge mb-0">
                            <div className="container">
                              <div className="row">
                                <div className="col pr-0 pl-0  -call-reflection">
                                  <div className="container">
                                    <div className="main-slider-left">
                                      <h1>
                                        Workativ Assistant is not a replacement
                                        for an ITSM tool and certainly not the
                                        traditional service desk.
                                      </h1>
                                      <p className="wv-md-heading1 wv-weight-normal wv-text-primary wv-limited-content">
                                        Workativ Assistant provides better and
                                        modern self-service led by AI and
                                        automation so, employees can
                                        self-resolve issues and requests, and
                                        companies can truly deflect calls away
                                        from the help desk.
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </section>
                          <section className="benefits_knowledge">
                            <div className="container">
                              <div className="row">
                                <div className="">
                                  <div className="">
                                    <section
                                      className="cards_without_bg"
                                      id="section-3"
                                    >
                                      <h4 className="header_h4">
                                        Improve self-service with Workativ
                                        Assistant
                                      </h4>
                                      <p className="wv-md-heading1 wv-weight-normal wv-text-primary wv-limited-content">
                                        Support teams can build virtual
                                        assistant with out of the box automation
                                        capabilities in just a few days, to
                                        automate resolution of common issues and
                                        requests. Automated resolution
                                        considerably reduces costs as employees
                                        will not be dependent on internal
                                        support agents to resolve issues. For
                                        every ticket automated with Workativ
                                        Assistant, you save costs in the range
                                        of 40-60% in a month depending on
                                        current help desk spend and ticket
                                        drivers.
                                      </p>
                                      <div className="row">
                                        <div className="col-md-6 col-sm-12 col-xs-12 mb-4">
                                          <img loading = "lazy"
                                            src={tick_icon}
                                            rel="Tick icon"
                                            height={32}
                                            width={32}
                                            className="mb-4 max-width-35"
                                          />
                                          <h4 className="wv-heading-h4 mb-2">
                                            It’s quicker
                                          </h4>
                                          <p className="wv-md-heading1 wv-weight-normal wv-text-primary wv-limited-content">
                                            No browsing a knowledge base to get
                                            the answer resolved.
                                          </p>
                                        </div>
                                        <div className="col-md-6 col-sm-12 col-xs-12 mb-4">
                                          <img loading = "lazy"
                                            src={tick_icon}
                                            rel="Tick icon"
                                            height={32}
                                            width={32}
                                            className="mb-4 max-width-35"
                                          />
                                          <h4 className="wv-heading-h4 mb-2">
                                            No human intervention
                                          </h4>
                                          <p className="wv-md-heading1 wv-weight-normal wv-text-primary wv-limited-content">
                                            The assistant is designed with
                                            automation capabilities to resolve
                                            issues instantly, thereby deflecting
                                            calls away from helpdesk.
                                          </p>
                                        </div>
                                      </div>
                                      <div className="row mb-4">
                                        <div className="col-md-6 col-sm-12 col-xs-12">
                                          <img loading = "lazy"
                                            src={tick_icon}
                                            rel="Tick icon"
                                            height={32}
                                            width={32}
                                            className="mb-4 max-width-35"
                                          />
                                          <h4 className="wv-heading-h4 mb-2">
                                            Reduced wait times
                                          </h4>
                                          <p className="wv-md-heading1 wv-weight-normal wv-text-primary wv-limited-content">
                                            Employees won’t be checking the
                                            status of their tickets as much as
                                            they used to because of the
                                            innovative self-service you provide.
                                            You’ll find that tickets reach a
                                            close faster.
                                          </p>
                                        </div>
                                      </div>
                                    </section>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </section>
                        </section>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </>
          <KeyResources cards={resoucesData} />
          <GoToPopup />
          <RequestForm isFooterForm={true} />
        </Layout>
        {/* <OnScrollPopup /> */}
      </Container>
    </>
  );
}
